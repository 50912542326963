import type { RouteLocationRaw } from "vue-router"
import useAccessControl from "~/layers/accessControl/composables/accessControl"
import { navItems } from "~/navigation"
import agent from "~/navigation/agent"

export interface TairoCollapseResolvedConfig {
  name: string
  divider?: boolean
  icon: {
    name: string
    class?: string
  }
  children?: any[]
  component?: {
    name: string
    props?: any
  }
  to?: RouteLocationRaw
  click?: () => void | Promise<void>
  activePath?: string
  /**
   * @default 'start'
   */
  position?: "start" | "end"
}

export function useCollapse() {
  const app = useAppConfig()
  const { userCan, hasRoles } = useAccessControl()

  const isMenuShow = (navigation: any) => {
    const routePermissions = navigation?.permissions as
      | string
      | string[]
      | undefined
    const routeRoles = navigation?.roles as string | string[] | undefined
    if (hasRoles("Super Admin")) {
      return true
    }
    if (!routePermissions && !routeRoles) {
      return true
    }
    if (routePermissions && userCan(routePermissions)) {
      return true
    }

    return !!(routeRoles && hasRoles(routeRoles))
  }

  const collapseMenuItems = computed(() => {
    if (!(app.tairo?.collapse?.navigation?.enabled as boolean)) {
      return []
    }
    const isSales = hasRoles("Sales")
    const navMenu = !isSales ? navItems : agent

    return navMenu?.map(
      (navigation: any) =>
        <TairoCollapseResolvedConfig>{
          ...navigation,
          position: navigation?.position ?? "start",
        },
    )
  })

  const menuItems = computed(() =>
    collapseMenuItems.value?.filter((i: any) => isMenuShow(i)),
  )

  const isOpen = useState("collapse-open", () => true)
  const isMobileOpen = useState("collapse-mobile-open", () => false)

  const header = computed(() => {
    return app.tairo?.collapse?.navigation?.header
  })

  const footer = computed(() => {
    return app.tairo?.collapse?.navigation?.footer
  })

  function toggle() {
    // If no sidebar item is selected, open the first one
    const { lg } = useTailwindBreakpoints()
    if (lg.value) {
      isOpen.value = !isOpen.value
    } else {
      isMobileOpen.value = !isMobileOpen.value
    }
  }

  if (import.meta.client) {
    const route = useRoute()
    const { lg } = useTailwindBreakpoints()
    watch(lg, (isLg) => {
      if (isLg) {
        isMobileOpen.value = false
      }
    })
    watch(
      () => route.fullPath,
      () => {
        if (!lg.value) {
          isMobileOpen.value = false
        }
      },
    )
  }

  return {
    toggle,
    menuItems,
    isOpen,
    isMobileOpen,
    header,
    footer,
  }
}
