export default [
  {
    name: "Application",
    icon: { name: "icon-park-solid:application-two", class: "w-5 h-5" },
    activePath: "/application",
    permissions: ["Application"],
    children: [
      {
        name: "Tax Slip (BUPOT)",
        to: "/application/tax-slip",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Tax Slip"],
      },
      {
        name: "PPh 21",
        to: "/application/income-tax",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
        permissions: ["View Income Tax"],
      },
    ],
  },
]
